import './OHIFLogo.css';

import { Icon } from '@ohif/ui';
import React from 'react';

function OHIFLogo() {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="header-brand"
      href="https://rngmedcons.com"
    >
      <Icon name="rng_blue" className="header-logo-image" />
      <div className="header-logo-text">Medical Legal Solutions LLC</div>
    </a>
  );
}

export default OHIFLogo;
